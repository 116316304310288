import React, { useRef, useLayoutEffect, useState } from "react"
import styled from "styled-components"

import readingTime from "reading-time"

import { Layout, Seo, HContainer } from "components/common"
import Footer from "components/navigation/Footer"
import { useMeta, useRichText, richTextBody, device } from "utils"

import { PostCard, PostTags } from "src/components/post"

import BottomNewsletterCta from "src/components/common/shared/BottomNewsletterCta"
// import { HomeContact } from "src/sections/home"

import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

const PostTemplate = ({ post, bottom_cta, type }) => {
  const { title, authors, cover, body, excerpt, tags } = post

  const ref = useRef()
  const [time, setTime] = useState({})

  useLayoutEffect(() => {
    const defaultTime = ref.current ? readingTime(ref.current.innerHTML) : {}
    setTime(defaultTime)
  }, [ref])

  const meta = useMeta({
    metaTitle: title,
    metaDescription: excerpt.childMarkdownRemark.rawMarkdownBody,
    metaImage: cover,
  })

  const renderPostBody = useRichText({ text: body })

  return (
    <Layout>
      <Seo {...meta} />

      <Nav />
      <MobileNav />

      <StyledPost>
        <HContainer className="postHero">
          <PostCard type={type} post={post} authors={authors} time={time} />
        </HContainer>

        {/* <ScrollProgress> */}
        {/* </ScrollProgress> */}
        <PostBody ref={ref}>
          <HContainer className="postMax">
            <div className="body">{renderPostBody}</div>
            <PostTags tags={tags} type={type} />
          </HContainer>
        </PostBody>

        <BottomNewsletterCta {...bottom_cta} />

        {/* <VSection
          className="moreRecent"
          hat="MORE RECENT BLOG POSTS"
          hatAs="h5"
          custom={() => (
            <MoreBlogPosts
              blogPosts={blogPosts}
              extraBlogPosts={extraBlogPosts}
            />
          )}
        /> */}
      </StyledPost>
      <Footer />
    </Layout>
  )
}

const StyledPost = styled.div`
  position: relative;
  z-index: 1;

  padding-top: var(--sp-mobile-nav);
  padding-bottom: var(--sp-section-mobile);

  @media ${device.laptop} {
    padding-top: var(--sp-nav);
    padding-bottom: var(--sp-section);
  }

  #footer {
    @media ${device.desktopL} {
      position: relative;
    }
  }

  .postHero {
    --section-max-width: 925px;
    --section-background-color: none;
    /* margin-top: 15vh; */

    .postCard {
      cursor: default;

      .animatedCursor {
        display: none;
      }

      @media ${device.laptop} {
        /* cancel out */
        &:hover {
          .postCardImage {
            transform: scale(1);
          }
        }
      }
    }
  }

  .moreRecent {
    --section-max-width: 1080px;
    --section-background-color: var(--lightCard2);
  }

  /* #contact {
    height: auto;
    --section-mobile-padding-top: var(--sp-section-mobile);
    --section-mobile-padding-bottom: var(--sp-section-mobile);
    --section-padding-top: var(--sp-120);
    --section-padding-bottom: var(--sp-section);

    .v_mediaContainer {
      display: none;
    }
  } */
`

const PostBody = styled.section`
  margin-top: var(--sp-32);

  @media ${device.laptop} {
    margin-top: var(--sp-56);
  }
  .postMax {
    --section-max-width: 730px;

    .body {
      ${richTextBody};

      p {
        color: var(--blog-body-color);
        word-wrap: break-word;
      }
      
      a {
        color: #6B8DFF;
      }
    }
  }
`

export default PostTemplate
