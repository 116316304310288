import React from "react"
import { graphql } from "gatsby"

import PostTemplate from "src/templates/PostTemplate.js"

const ListingTemplate = ({ data: { listing, bottom_cta } }) => {
  return <PostTemplate bottom_cta={bottom_cta} post={listing} type="listing" />
}

export default ListingTemplate

export const query = graphql`
    query PostListingSlug($slug: String) {
        listing: contentfulListing(slug: { eq: $slug }) {
            ...Listing
        }
        bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
            ...Section
        }
        # blogPosts: allContentfulBlogPost(
        #   filter: { slug: { ne: $slug } }
        #   sort: { order: DESC, fields: date }
        #   limit: 3
        # ) {
        #   edges {
        #     node {
        #       ...BlogPost
        #     }
        #   }
        # }
        # extraBlogPosts: allContentfulBlogPost(
        #   filter: { slug: { ne: $slug } }
        #   sort: { order: DESC, fields: date }
        #   limit: 3
        # ) {
        #   edges {
        #     node {
        #       ...BlogPost
        #     }
        #   }
        # }
    }
`
